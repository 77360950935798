import { Component } from '@angular/core';

@Component({
  selector: 'atar-academy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'training';
}
